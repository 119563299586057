<template>
  <b-modal
    v-model="showModal"
    ref="modal"
    centered
    size="md "
    hide-footer
    hide-header
    class="modal"
    @click="focusInput"
    footer-class="pt-0 border-0"
    no-close-on-backdrop
  >
    <div @click="focusInput">
      <div class="text-right text-gray text-link" @click="logout">
        ออกจากระบบ
      </div>

      <div class="d-flex justify-content-center logo-container">
        <div
          class="logo-home-page-desktop"
          v-bind:style="{
            'background-image': 'url(' + $store.state.logoHomePage + ')',
          }"
        >
          <!-- <img :src="'https://api-hourglass-booth-dev.dosetech.co/Upload/242803012800173.png'" alt="" class="w-100 h-auto" /> -->
        </div>
      </div>
      <div class="text-center">กรอกรหัส PIN ของคุณ</div>

      <div class="d-flex justify-content-center mt-3 text-pin">
        <div v-for="(pin, index) in pinCodeDisplay" :key="index">
          <font-awesome-icon
            v-if="!pin.value"
            :icon="['far', 'circle']"
            class="mx-2"
          />
          <font-awesome-icon
            v-else-if="pin.hidePin"
            :icon="['fa', 'circle']"
            class="mx-2"
          />
          <div v-else-if="pin.value" class="mx-2">{{ pin.value }}</div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <div>
          <p class="text-error text-center">{{ errorMsg }}</p>
          <p v-if="isLock && errorMsg" class="text-error text-center">
            เนื่องจากกรอกผิดครบจำนวน 5 ครั้ง
          </p>
        </div>
      </div>
      <div class="d-flex justify-content-center my-3 numpad-container">
        <b-row class="w-300px">
          <b-col
            v-for="(num, index) in numPad"
            :key="`numpad${index}`"
            cols="4"
            class="d-flex justify-content-center"
          >
            <div class="py-2">
              <div
                @click="updatePinCodeClick(num.value)"
                v-if="index !== 9 && index !== 11"
                class="numpad d-flex justify-content-center align-items-center bg-gray-box"
                :class="{
                  'text-gray': index !== 11,
                  'numpad-click': num.isClick,
                }"
              >
                {{ num.value }}
              </div>

              <div
                @click="submitPin()"
                v-else-if="index == 11"
                class="numpad numpad-login d-flex justify-content-center align-items-center"
                :class="{
                  'numpad-login-lock': isLock,
                  'numpad-login-click': num.isClick,
                }"
              >
                {{ num.value }}
              </div>
              <div
                v-else
                @click="updatePinCodeClick('delete')"
                :class="{
                  'numpad-click': num.isClick,
                }"
                class="numpad-delete d-flex justify-content-center align-items-center border"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                  <path
                    fill="#7c7a7a"
                    d="M576 128c0-35.3-28.7-64-64-64L205.3 64c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7L512 448c35.3 0 64-28.7 64-64l0-256zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
                  />
                </svg>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <input
        type="text"
        v-model="pinCode"
        ref="pinInput"
        @input="updatePinCode"
        :disabled="isLock"
        maxlength="6"
        autofocus
        @keypress="allowOnlyNumbers"
        class="invisible-input d-md-block d-none"
      />
    </div>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showModal: false,
      pinCode: "",
      pinCodeDisplay: [],
      hidePin: false,
      hideTimeout: null,
      errorMsg: "",
      numPad: [],
      timeDiff: null,
      isLoading: false,
      deviceId: "",
      isLock: false,
    };
  },
  computed: {
    ...mapGetters({
      userGuid: "getUserGuid",
      branchId: "getBranchId",
      require_pin_login: "getRequirePin",
      mainLoading: "getMainLoading",
      time_lock_pin: "getTimeLockPin",
    }),
  },
  async created() {
    while (this.pinCodeDisplay.length < 6) {
      this.pinCodeDisplay.push({ value: "", hidePin: false });
    }
    this.numPad.push(
      { value: 1, isClick: false },
      { value: 2, isClick: false },
      { value: 3, isClick: false },
      { value: 4, isClick: false },
      { value: 5, isClick: false },
      { value: 6, isClick: false },
      { value: 7, isClick: false },
      { value: 8, isClick: false },
      { value: 9, isClick: false },
      { value: "delete", isClick: false },
      { value: 0, isClick: false },
      { value: "เข้าสู่ระบบ", isClick: false }
    );
    let getSaleId = this.$cookies.get("saleId");

    if (this.require_pin_login == "1" && !getSaleId) {
      this.showModal = true;
      await this.getCheckisLock(true);
    } else if (this.require_pin_login == "0") {
      this.showModal = false;
      return;
    }
  },

  methods: {
    updatePinCode(event) {
      if (this.isLock) {
        return;
      }
      const value = event.target.value.replace(/\D/g, "");
      this.pinCode = value.slice(0, 6);
      this.updatePinCodeDisplay(event.data);
    },
    async allowOnlyNumbers(event) {
      const keyCode = event.keyCode || event.which;

      if (event.keyCode == 13) {
        await this.submitPin();
      } else if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    updatePinCodeClick(num) {
      if (this.isLock) {
        return;
      }
      if (num == "เข้าสู่ระบบ") {
        return;
      } else if (num === "delete") {
        this.pinCode = this.pinCode.slice(0, -1);
        this.updatePinCodeDisplay(num);
        return;
      } else if (this.pinCode.length === 6) {
        return;
      }

      let numtext = num.toString();
      this.pinCode += numtext;
      this.updatePinCodeDisplay(num);
    },
    updatePinCodeDisplay(value) {
      let currentPin = this.pinCodeDisplay.find(
        (x, index) => !x.value && index < 6
      );

      let clickPin = this.numPad.find((x) => x.value == value);
      let deletePin = this.numPad.find((x) => x.value == "delete");

      if (currentPin && value !== "delete" && value !== null) {
        currentPin.value = value.toString();

        clickPin.isClick = true;
        this.$nextTick(() => {
          setTimeout(() => {
            currentPin.hidePin = true;
            clickPin.isClick = false;
          }, 300);
        });
      } else {
        let lastPin = [...this.pinCodeDisplay].reverse().find((x) => x.value);
        if (lastPin) {
          lastPin.value = "";
          lastPin.hidePin = false;
          deletePin.isClick = true;
          this.$nextTick(() => {
            setTimeout(() => {
              deletePin.isClick = false;
            }, 300);
          });
        }
      }
      if (this.pinCode.length === 6) {
        this.submitPin();
      }
    },

    focusInput() {
      setTimeout(() => {
        if (this.$refs.pinInput) this.$refs.pinInput.focus();
      }, 100);
    },
    async getCheckisLock(isOpen) {
      const res = await this.$axios(`${this.$baseUrl}/admin/is_account_lock`);

      if (!res.detail.is_lock && res.detail.totalFail && !isOpen) {
        this.errorMsg = `กรอก Pin ไม่ถูกต้อง (${res.detail.totalFail}/5)`;
      } else if (res.detail.is_lock) {
        this.isLock = true;
        let createdTime = new Date(res.detail.created_time);
        let currentTime = new Date();

        this.timeDiff =
          60000 * this.time_lock_pin -
          (currentTime.getTime() - createdTime.getTime());
        this.countDownTimer();
      }
    },
    countDownTimer() {
      if (this.timeDiff > 0) {
        setTimeout(() => {
          this.timeDiff -= 1000;
          this.errorMsg = `Pin ถูก lock ${this.formatTime(
            this.timeDiff
          )} นาที  `;
          this.countDownTimer();
        }, 1000);
      } else {
        this.errorMsg = "";
        this.isLock = false;
      }
    },

    formatTime(time) {
      let minutes = Math.floor(time / 60000);
      let seconds = Math.floor((time % 60000) / 1000);
      let formattedTime = `${String(minutes).padStart(2, "0")}:${String(
        seconds
      ).padStart(2, "0")}`;
      return formattedTime;
    },
    async submitPin() {
      if (this.isLock) {
        return;
      }

      this.numPad[11].isClick = true;
      setTimeout(() => {
        this.numPad[11].isClick = false;
      }, 300);

      if (this.pinCode.length < 6) {
        this.pinCode = "";
        this.resetPnDisplay();
        this.errorMsg = "กรุณากรอก PIN ให้ครบ 6 ตัว";
        return;
      }

      if (!this.isLoading) {
        this.isLoading = true;
        await this.$store.dispatch("setMainLoading", true);
        let payload = {
          branch_id: this.branchId,
          pin: this.pinCode,
        };
        const res = await this.$axios.post(
          `${this.$baseUrl}/admin/check_pin`,
          payload
        );
        if (res.result) {
          this.errorMsg = "";
          this.$emit("updateSaleId", res.detail.sales_no, this.pinCode);
          this.showModal = false;
          this.pinCode = "";
          this.resetPnDisplay();
        } else if (res.detail.totalFail == 5) {
          this.pinCode = "";
          this.resetPnDisplay();
          this.errorMsg = "";
          await this.getCheckisLock();
          this.isLock = true;
        } else if (!res.detail.is_lock && res.detail.totalFail) {
          this.pinCode = "";
          this.resetPnDisplay();
          this.errorMsg = `กรอก Pin ไม่ถูกต้อง (${res.detail.totalFail}/5)`;
        } else {
          // this.pinCode = "";
          // this.resetPnDisplay();
          // this.errorMsg = "";
          // this.isLock = true;
          // let createdTime = new Date(res.detail.created_time);
          // let currentTime = new Date();
          // this.timeDiff =
          //   60000 * this.time_lock_pin -
          //   (currentTime.getTime() - createdTime.getTime());
          // this.countDownTimer();
        }
      }
      await this.$store.dispatch("setMainLoading", false);

      this.isLoading = false;
    },
    resetPnDisplay() {
      this.pinCodeDisplay = [];
      while (this.pinCodeDisplay.length < 6) {
        this.pinCodeDisplay.push({ value: "", hidePin: false });
      }
    },

    logout() {
      var timestamp = new Date().getTime();
      this.$cookies.set("timestamp", timestamp);
      this.$cookies.remove("tnl-username");
      this.$cookies.remove("token");
      this.$cookies.remove("username");
      this.$cookies.remove("branch_id");
      this.$cookies.remove("plant_id");
      this.$cookies.remove("user_guid");
      this.$cookies.remove("sales_no");
      this.$cookies.remove("booth");
      this.$cookies.remove("spa");
      this.$cookies.remove("invoice_length");
      this.$cookies.remove("is_fullpos");
      this.$cookies.remove("is_invoice_leading_zero");
      this.$cookies.remove("allow_past_coupon");
      this.$cookies.remove("account_name");
      this.$cookies.remove("allow_time_change");
      this.$cookies.remove("booth_show_inventory");
      this.$cookies.remove("branch_name");
      this.$cookies.remove("point_system");
      this.$cookies.remove("allow_time_change_service");
      this.$cookies.remove("saleId");
      window.location.href = "/";
    },
  },
};
</script>
<style lang="scss" scoped>
.logo-home-page-desktop {
  min-height: 80px !important;
  min-width: 80px !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: auto;
  height: 80px;

  aspect-ratio: 16 / 9;
}

.invisible-input {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.numpad {
  font-size: 24px;
  width: 60px;
  height: 60px;
  border-radius: 80%;
  transition: 0.3s all;
}

.numpad-login {
  background-color: var(--primary-color);
  font-size: 10px !important;
  color: var(--font-primary-color) !important;
  transition: 0.3s all;
}

.numpad-login-lock {
  background-color: #f1f1f1 !important;
  font-size: 10px !important;
  color: #333333 !important;
}

.numpad-delete {
  width: 30px;
  color: #9b9b9b;
  width: 60px;
  height: 60px;
  border-radius: 80%;
  transition: 0.3s all;
}

::v-deep .modal-dialog {
  max-width: 400px;
}

.w-300px {
  width: 300px;
}
.w-250px {
  width: 250px;
}

svg {
  width: 30px;
}

.text-link {
  text-decoration: underline;
  cursor: pointer;
}

.numpad-click {
  background-color: var(--primary-color) !important;
  color: var(--font-primary-color) !important;
  font-size: 30px;
}

.numpad-login-click {
  transition: 0.3s all;
  background-color: var(--secondary-color) !important;
  font-size: 13px !important;
  color: var(--font-primary-color) !important;
}

.text-pin {
  font-weight: 800;
}
</style>

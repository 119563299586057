<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">ยืนยันการรวมบิล {{ form.invoiceNo }}</div>
      </template>
      <p class="text-center label">
        กรอก Invoice No ที่ต้องการรวมกับ Invioce No {{ form.invoiceNo }}
      </p>
      <div class="mt-3 p-3">
        <b-row class="no-gutters">
          <b-col cols="4"
            ><p class="label mt-2 text-right pr-2">Invoice No. :</p>
          </b-col>
          <b-col cols="7">
            <InputText
              v-model="form.refNo"
              placeholder="Invoice No."
              type="text"
              name="refNo"
              className="mb-2"
              isRequired
              :isValidate="$v.form.refNo.$error"
              :v="$v.form.refNo"
            />
          </b-col>
        </b-row>
        <b-row class="no-gutters mt-3">
          <b-col cols="4"
            ><p class="label mt-2 text-right pr-2">Sale ID. :</p></b-col
          >

          <b-col cols="7">
          
            <InputText
              v-model="form.saleId"
              textFloat=""
              placeholder="Sale ID"
              type="text"
              name="saleId"
              className="mb-2"
              isRequired
              :disabled="isLockSaleId"
              :isValidate="$v.form.saleId.$error"
              :v="$v.form.saleId"
            >
              <template v-slot:text> </template>
            </InputText>
            <u
              v-if="require_pin_login !== '0'"
              @click="openModalPin()"
              class="text-desc-message text-black font-weight-bold pointer d-flex justify-content-end"
              >เปลี่ยนพนักงาน</u
            >
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 mr-1"
            @click="save"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 ml-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalPin ref="ModalPin" @updateSaleId="updateSaleId"></ModalPin>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import { required } from "vuelidate/lib/validators";
import ModalPin from "../../views/pages/home/components/ModalPin.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    InputText,
    InputSelect,
    ModalPin,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: { id: 0, invoiceNo: "", refNo: "", saleId: "" },
      isLockSaleId: false,
    };
  },
  validations() {
    return {
      form: {
        refNo: {
          required,
        },
        saleId: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      require_pin_login: "getRequirePin",
    }),
  },

  methods: {
    async show(item) {
      this.form.id = item.id;
      this.form.invoiceNo = item.invoice_no;
      this.form.refNo = "";
      this.form.saleId = "";

      this.$v.form.$reset();
      let getSaleId = this.$cookies.get("saleId");
      if (getSaleId && this.require_pin_login == "1") {
        this.form.saleId = getSaleId;

        this.isLockSaleId = true;
      }
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async save() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      await this.$store.dispatch("setMainLoading", true);
      this.isLoading = true;
      let body = {
        invoiceNo_A: this.form.invoiceNo,
        invoiceNo_B: this.form.refNo,
        sales_id: this.form.saleId,
        branch_id: this.branchId,
      };
      await this.axios
        .post(`${this.$baseUrl}/transaction/MergePoint`, body)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$emit("reloadDataTable");
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
      this.isLoading = false;
    },
    async updateSaleId(saleId, pin) {
      this.isLockSaleId = true;
      this.form.saleId = saleId;
      this.$cookies.set("saleId", saleId, { expires: "1d" });
    },
    openModalPin() {
      this.$cookies.remove("saleId");
      this.form.saleId = "";
      this.$refs.ModalPin.showModal = true;
      this.$refs.ModalPin.focusInput();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.label {
  color: #333 !important;
  font-weight: bold !important;
}
</style>

import Vue from "vue";
// import axios from "axios"
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  mainLoading: false,
  showPolicy: false,
  showTerm: false,
  dateTimeFix: null,
  temp: {
    register: {
      memberId: "",
      userGuid: "",
    },
  },
});

export const mutations = {
  UPDATE_MAIN_LOADING: (state, payload) => {
    state.mainLoading = payload;
  },
  UPDATE_POLICY_MODAL: (state, payload) => {
    state.showPolicy = payload;
  },
  UPDATE_TERM_MODAL: (state, payload) => {
    state.showTerm = payload;
  },
  UPDATE_DATE_TIME_FIX: (state, payload) => {
    Vue.set(state, "dateTimeFix", payload);
  },
  UPDATE_TEMP_REGISTER: (state, payload) => {
    state.temp.register = { ...payload };
  },
};

export const actions = {
  setMainLoading(context, payload) {
    context.commit("UPDATE_MAIN_LOADING", payload);
  },
  setPolicyModal(context, payload) {
    context.commit("UPDATE_POLICY_MODAL", payload);
  },
  setTermModal(context, payload) {
    context.commit("UPDATE_TERM_MODAL", payload);
  },
  setDateTimeFix(context, payload) {
    context.commit("UPDATE_DATE_TIME_FIX", payload);
  },
  setTempRegisiter(context, payload) {
    context.commit("UPDATE_TEMP_REGISTER", payload);
  },
};
export const getters = {
  getMainLoading: (state) => {
    return state.mainLoading;
  },
  getPolicy: (state) => {
    return state.showPolicy;
  },
  getTerm: (state) => {
    return state.showTerm;
  },
  getDateTimeFix: (state) => {
    return state.dateTimeFix;
  },
  getTempRegisiter: (state) => {
    return state.temp.register;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};

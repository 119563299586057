<template>
  <div class="d-flex align-items-center min-vh-100 bg">
    <b-container>
      <div v-if="isLoading">
        <OtherLoading />
      </div>
      <b-row class="justify-content-center" v-else>
        <b-col md="9" lg="6" class="login">
          <b-card-group>
            <b-card class="p-4 shadow-lg">
              <b-card-body class="login-box">
                <b-form>
                  <div class="text-center img-logo-cover">
                    <div
                      class="imgLogo"
                      v-bind:style="{
                        'background-image':
                          'url(' + $store.state.logoHomePage || imgLogo + ')',
                      }"
                    ></div>
                    <hr class="my-4" />
                  </div>
                  <InputText
                    id="email"
                    class="my-3 login-input"
                    v-model="form.Email"
                    textFloat="Email"
                    placeholder="ชื่อผู้ใช้งาน"
                    type="text"
                    name="email"
                    isRequired
                    :isValidate="$v.form.Email.$error"
                    :v="$v.form.Email"
                    @onKeyup="onEmailKeyup"
                  />
                  <InputText
                    class="my-3 login-input"
                    v-model="form.Password"
                    textFloat="Password"
                    placeholder="รหัสผ่าน"
                    type="password"
                    name="password"
                    isRequired
                    :isValidate="$v.form.Password.$error"
                    :v="$v.form.Password"
                    isShowPassword
                    @onKeyup="submitFormOnInput"
                  />
                  <b-row v-if="error != ''" class="m-2 text-center">
                    <span class="text-danger w-100">
                      {{ error }}
                      <br />
                      {{ " " }}
                    </span>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-button
                        type="button"
                        class="px-4 login-btn"
                        :style="`background-color: ${$store.state.colorSecondary};border: 1px solid ${$store.state.colorSecondary}`"
                        @click="checkForm"
                        :disabled="isDisable"
                        >เข้าสู่ระบบ
                        <b-spinner
                          class="align-middle"
                          v-if="isLogin"
                        ></b-spinner
                      ></b-button>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import InputText from "@/components/inputs/InputText";
import imgLogo from "@/assets/images/icons/logo.svg";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  name: "Login",
  components: {
    InputText,
    OtherLoading,
  },
  data() {
    return {
      imgLogo: imgLogo,
      error: "",
      form: {
        Email: "",
        Password: "",
      },
      isLoading: false,
      isDisable: false,
      isLogin: false,
      passwordType: "",
      defaultDomain: "@dcrm.com",
      isNavigation: false,
    };
  },
  validations() {
    return {
      form: {
        Email: { required, email },
        Password: { required },
      },
    };
  },
  mounted: async function () {
    this.defaultDomain =
      "@" +
      window.location.host
        .split(".")[0]
        .replace("booth", "")
        .replace("thailand", "") +
      ".com";
  },
  async created() {
    this.isLoading = true;
    this.$cookies.remove("token");
    this.isLoading = false;
  },
  methods: {
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      this.isDisable = true;
      this.isLogin = true;
      this.error = "";
      this.axios
        .post(`${this.$baseUrl}/customer/login`, this.form)
        .then(async (data) => {
          this.isDisable = false;
          this.isLogin = false;
          this.isLoading = false;
          if (data.result == 1) {
 
            var timestamp = new Date().getTime();
            this.$cookies.set("timestamp", timestamp);
            this.$store.dispatch("setTimestamp", timestamp);

            await this.$cookies.set("token", data.detail.token);
            await this.$cookies.set("tnl-username", data.detail.account_name);
            await this.$cookies.set("branch_id", data.detail.branch_id);
            await this.$cookies.set("branch_name", data.detail.name);
            await this.$cookies.set("branch_type_name", data.detail.branch_type_name);
            await this.$cookies.set("account_name", data.detail.account_name);
            await this.$cookies.set("name", data.detail.name);
            await this.$cookies.set("user_guid", data.detail.user_guid);
            await this.$cookies.set("plant_id", data.detail.plant_id);
            await this.$cookies.set("booth", data.detail.booth);
            await this.$cookies.set("spa", data.detail.spa);
            await this.$cookies.set(
              "invoice_length",
              data.detail.invoice_length
            );
            await this.$cookies.set("is_fullpos", data.detail.is_fullpos);
            await this.$cookies.set(
              "is_invoice_leading_zero",
              data.detail.is_invoice_leading_zero
            );
            await this.$cookies.set(
              "allow_past_coupon",
              data.detail.allow_past_coupon
            );
            await this.$cookies.set(
              "allow_time_change",
              data.detail.allow_time_change
            );
            await this.$cookies.set(
              "booth_show_inventory",
              data.detail.booth_show_inventory
            );
            await this.$cookies.set("point_system", data.detail.point_system);
            await this.$cookies.set(
              "allow_time_change_service",
              data.detail.allow_time_change_service
            );
            this.$store.dispatch("setSlideBarCurrent", 1);

            window.location.href = "/";
          } else {
            this.error = data.message;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    submitFormOnInput: function (e) {
      if (e.keyCode === 13) {
        this.checkForm();
      }
    },
    handleShowHidePassword() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    onEmailKeyup(e) {
      const pattern = new RegExp(
        /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]$/
      );

      if (e.keyCode === 8) {
        return;
      } else if (e.keyCode === 13) {
        this.checkForm();
      } else if (![37, 39, 91].includes(e.keyCode)) {
        if (!e.key.includes("@")) {
          if (
            e.target.value &&
            !e.target.value.includes(this.defaultDomain) &&
            !e.target.value.includes("@")
          ) {
            this.form.Email = e.target.value + this.defaultDomain;
          } else if (e.target.value.includes("@@")) {
            this.form.Email = e.target.value.replace(this.defaultDomain, "");
          }
          if (this.form.Email.includes(this.defaultDomain)) {
            if ([65, 67, 17, 86].includes(e.keyCode)) {
              return;
            }

            setTimeout(() => {
              let value = e.target.value;
              const input = document.getElementById("email");
              const selectionStart = e.target.selectionStart;
              let sectionEnd = value.indexOf("@");

              if (selectionStart !== sectionEnd) {
                value =
                  value
                    .split(this.defaultDomain)[0]
                    .substring(0, selectionStart) + this.defaultDomain;

                sectionEnd = value.indexOf("@");
                this.form.Email = value;
              }

              setTimeout(() => {
                input.setSelectionRange(sectionEnd, sectionEnd);
              }, 0);
            }, 10);
          }
        } else {
          this.form.Email = e.target.value.replace(this.defaultDomain, "");
        }
      }
    },
  },
};
</script>

<style scoped>
.bg {
  background-color: var(--primary-color);
}
</style>

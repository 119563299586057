<template>
  <div>
    <b-sidebar
      id="sidebar-menu"
      v-model="$store.state.slidebarCustom"
      backdrop
      shadow
      backdrop-variant="dark"
      aria-labelledby="sidebar-menu-title"
      @hidden="hide()"
      no-header
    >
      <template>
        <div>
          <div
            class="p-2 border-bottom d-flex justify-content-between align-items-center"
          >
            <label class="m-0 header-text"><strong>เมนู</strong></label>
            <label
              class="m-0 pointer"
              @click="$store.commit('toggleSidebarCustom')"
              ><b-icon icon="x-circle-fill"></b-icon
            ></label>
          </div>

          <div
            v-for="(menu, index) in menuOption"
            :key="index"
            @click.stop="gotoPath(menu)"
          >
            <div
              v-if="isDisplayMenu(menu.id)"
              class="menu-item p-2"
              :style="
                $route.fullPath == menu.path ||
                $route.meta.activeUrl == menu.path
                  ? `border-left: 4px solid ${$store.state.colorPrimary};`
                  : ``
              "
            >
              {{ menu.name }}
            </div>

            <div
              v-for="(menuChild, index) in menu.children"
              :key="index"
              @click.stop="gotoPath(menuChild)"
            >
              <div
                class="menu-item p-2 pl-4"
                :style="
                  $route.fullPath == menuChild.path ||
                  $route.meta.activeUrl == menu.path
                    ? `border-left: 4px solid ${$store.state.colorPrimary};`
                    : ``
                "
              >
                {{ menuChild.name }}
              </div>
            </div>
          </div>
        </div>
      </template>

      <template #footer>
        <div
          class="menu-item p-2 border-top"
          @click="$refs.modalLogout.handleLogout()"
        >
          <label class="marginbottom">ออกจากระบบ</label>
        </div>
      </template>
    </b-sidebar>

    <b-modal
      ref="alertModal"
      id="alertModal"
      hide-header
      hide-footer
      centered
      size="sm"
      no-close-on-backdrop
    >
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 img"
        />
        <p class="font-weight-bold">กรุณารอสักครู่...</p>
      </div>
    </b-modal>

    <ModalLogout ref="modalLogout" />
    <ModalRegister ref="modalRegister" :branchId="branchId" />
    <ModalChangeTime ref="modalChangeTime" :branchId="branchId" />
  </div>
</template>

<script>
import ModalLogout from '@/components/modal/alert/ModalLogout.vue';
import ModalRegister from '@/components/customer/ModalRegister.vue';
import ModalChangeTime from '@/components/modal/ModalChangeTime.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'SlidebarCustom',
  components: {
    ModalLogout,
    ModalRegister,
    ModalChangeTime
  },
  data() {
    return {
      showSlide: false,
      menuCurrent: 0,
      menuOption: [
        {
          id: 1,
          path: '/home',
          name: 'หน้าแรก',
          activeId: 1
        },
        {
          id: 2,
          path: '',
          name: 'ลูกค้า',
          activeId: 2,
          children: [
            {
              id: 3,
              path: '/customer/purchase-history',
              name: 'ลูกค้าที่มีประวัติการซื้อ',
              activeId: 2
            },
            {
              id: 4,
              path: '/customer/prospect',
              name: 'ลูกค้าที่ไม่มีประวัติการซื้อ',
              activeId: 2
            },
            {
              id: 5,
              path: '/customer/privileged',
              name: 'ลูกค้าที่ได้รับสิทธิพิเศษ',
              activeId: 2
            }
          ]
        },
        {
          id: 6,
          path: '/register',
          name: 'ลงทะเบียน',
          activeId: 6
        },
        {
          id: 7,
          path: '/time',
          name: 'ปรับเวลา',
          activeId: 7
        },
        {
          id: 8,
          path: '/summary',
          name: 'สรุปยอด',
          activeId: 8
        },
        {
          id: 9,
          path: '/transaction',
          name: 'บิล',
          activeId: 9
        },
        {
          id: 10,
          path: '/transaction-error',
          name: 'บิลที่มีปัญหา',
          activeId: 10
        },
        {
          id: 11,
          path: '/reward',
          name: 'รางวัล',
          activeId: 11,
          children: [
            {
              id: 12,
              path: '/redeem',
              name: 'แลกคะแนนสะสม',
              activeId: 11
            },
            {
              id: 13,
              path: '/mission',
              name: 'Membership Activity',
              activeId: 11
            }
          ]
        },
        {
          id: 14,
          path: '',
          name: 'รายงาน',
          activeId: 14,
          children: [
            {
              id: 15,
              path: '/report/point-balance',
              name: 'เช็คคะแนน',
              activeId: 14
            },
            {
              id: 16,
              path: '/report/ref-sale-member',
              name: 'ประวัติการซื้อ',
              activeId: 14
            },
            {
              id: 17,
              path: '/report/spa-usage-history',
              name: 'ประวัติการใช้',
              activeId: 14
            },
            {
              id: 18,
              path: '/report/outstanding',
              name: 'OUTSTANDING',
              activeId: 14
            },
            {
              id: 19,
              path: '/report/history-sale-category',
              name: 'ประวัติการซื้อแบ่งตามประเภทสินค้า',
              activeId: 14
            }
          ]
        },
        {
          id: 20,
          path: '/inventory',
          name: 'จัดการสินค้า',
          activeId: 20
        },
        {
          id: 21,
          path: '/request-purchase',
          name: 'สั่งผลิตสินค้าเพื่อเติมสต็อก',
          activeId: 21
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      branchId: 'getBranchId',
      allowTimeChange: 'getAllowTimeChange',
      isFullPos: 'getIsFullPos'
    })
  },
  methods: {
    isDisplayMenu(id) {
      if (id === 7) {
        if (this.isFullPos == 1 || this.allowTimeChange != 'true') return false;
      }

      return true;
    },
    async show() {
      this.showSlide = true;
    },
    hide() {
      this.showSlide = false;
    },
    gotoPath(menu) {
      if (menu.children && menu.children.length) return;
      this.$store.dispatch('setSlideBarCurrent', menu.id);
      this.$router.push(menu.path);
    },
    handleLogout() {
      this.$refs.modalLogout.show();
    },
    register() {
      this.$refs.modalRegister.show();
    },
    changeTime() {
      this.$refs.modalChangeTime.show();
    },
    logout() {
      this.$refs.logOutModal.hide();
      this.$refs.alertModal.show();
      var timestamp = new Date().getTime();
      this.$cookies.set('timestamp', timestamp);
      this.$cookies.remove('tnl-username');
      this.$cookies.remove('token');
      this.$cookies.remove('username');
      this.$cookies.remove('branch_id');
      this.$cookies.remove('plant_id');
      this.$cookies.remove('user_guid');
      this.$cookies.remove('sales_no');
      this.$cookies.remove('booth');
      this.$cookies.remove('spa');
      this.$cookies.remove('invoice_length');
      this.$cookies.remove('is_fullpos');
      this.$cookies.remove('is_invoice_leading_zero');
      this.$cookies.remove('allow_past_coupon');
      this.$cookies.remove('account_name');
      this.$cookies.remove('allow_time_change');
      this.$cookies.remove('booth_show_inventory');
      this.$cookies.remove('branch_name');
      this.$cookies.remove('point_system');
      this.$cookies.remove('allow_time_change_service');

      window.location.href = '/';
    }
  }
};
</script>

<style scoped>
.marginbottom {
  margin-bottom: 0px;
}
.menu-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid #f1f1f1;
  cursor: pointer;
  color: var(--primary-color);
}
</style>

<template>
  <div>
    <b-modal
      v-model="getTerm"
      ref="modalTerms"
      hide-footer
      centered
      @hidden="hide"
      scroll-strategy="none"
      size="lg"
      title="ข้อกำหนดเงื่อนไข"
    >
      <div class="mb-4 scroll-policy">
        <div class="mt-3" v-html="policy"></div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    text: {
      required: false,
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      getTerm: "getTerm",
    }),
  },
  watch: {
    getTerm(val) {
      if (val) this.getData();
    },
  },
  data() {
    return {
      showModal: false,
      policy: "",
    };
  },

  methods: {
    async getData() {
      await this.axios
        .get(`${this.$baseUrl}/DSR/terms_condition`)
        .then(async (data) => {
          if (data.result === 1) {
            this.policy = data.detail;
          }
        });
    },

    hide() {
      this.$store.dispatch("setTermModal", false);
    },
  },
};
</script>

<style rel="scss" scoped>
.modal-header {
  border: 0 !important;
  padding: 0;
}

.modal-body {
  margin: auto;
  text-align: center;
}
.class-icon-black {
  width: 70px;
}

.scroll-policy {
  overflow-y: visible;
  overflow-x: hidden;
  /* height: 100vh; */
}

.ft-underline {
  text-decoration: underline;
}
::v-deep table {
  border-collapse: collapse;
  width: 100%;
}

::v-deep td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

::v-deep tr:nth-child(even) {
  background-color: #dddddd;
}
::v-deep .close {
  font-size: 1.25rem;
  right: 40px;
  top: 10px;
}
</style>

<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="modal"
      centered
      size="md"
      class="alert-modal"
      hide-header-close
      footer-class="pt-0 border-0"
    >
      <template #modal-header>
        <div class="modal-title">
          กรุณากรอกข้อมูลเพื่อยกเลิกรายการ {{ invoice_no }}
        </div>
      </template>
      <div class="d-flex justify-content-center py-3">
        <div class="d-block w-75">
          <InputText
            v-model="form.invoiceNo"
            textFloat="Ref no"
            className="mb-2"
            isRequired
            :isValidate="$v.form.invoiceNo.$error"
            :v="$v.form.invoiceNo"
            :disabled="isFullPos == 1"
            @onKeydown="handleInvoiceNo"
          />
          <InputText
            v-model="form.grandTotal"
            textFloat="ยอดเงิน"
            className="mb-2"
            isRequired
            :isValidate="$v.form.grandTotal.$error"
            :v="$v.form.grandTotal"
          />
          <InputText
            v-model="form.saleId"
            textFloat="Saleperson id"
            className="mb-2"
            ref="saleIdInput"
            :disabled="isLockSaleId"
            isRequired
            :isValidate="$v.form.saleId.$error"
            :v="$v.form.saleId"
            @onEnter="handleEnter"
          >
            <template v-slot:text>
              <u
                v-if="require_pin_login !== '0'"
                @click="openModalPin()"
                class="text-desc-message text-black font-weight-bold pointer"
                >เปลี่ยนพนักงาน
              </u>
            </template></InputText
          >
          <b-form-checkbox
            class="title mt-3"
            v-model="form.isReturnInventory"
            :value="1"
            :unchecked-value="0"
          >
            Inventory
          </b-form-checkbox>
        </div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="d-flex justify-content-between w-100 my-0">
          <b-button
            variant="close"
            class="btn-modal btn-hover w-50 mr-1"
            @click="hide('forget')"
            :disabled="isLoading"
          >
            ยกเลิก
          </b-button>
          <b-button
            variant="primary-color"
            class="btn-modal btn-hover w-50 ml-1"
            @click="cancelTransaction"
            :disabled="isLoading"
          >
            ยืนยัน
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalPin ref="ModalPin" @updateSaleId="updateSaleId"></ModalPin>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import { required, minValue, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import ModalPin from "./ModalPin";

export default {
  components: {
    InputText,
    ModalPin,
  },
  props: {
    branchId: {
      required: true,
      type: [String, Number],
    },
    fromInventoryId: {
      required: true,
      type: [String, Number],
    },
    invoice_no: {
      required: true,
      type: [String],
    },
    isFullPos: {
      required: true,
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      invoiceLength: "getInvoiceLength",
      isInvoiceLeadingZero: "getIsInvoiceLeadingZero",
      require_pin_login: "getRequirePin",
    }),
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      form: {
        transactionId: 0,
        invoiceNo: "",
        grandTotal: 0,
        saleId: "",
        branchId: 0,
        isReturnInventory: 1,
      },
      isLockSaleId: false,
    };
  },
  validations() {
    return {
      form: {
        invoiceNo: {
          required: requiredIf(function (item) {
            return this.isFullPos == 0;
          }),
        },
        saleId: { required },
        grandTotal: {
          required,
          minValue: minValue(0),
        },
      },
    };
  },
  created() {
  
  },
  methods: {
    clearData() {
      this.form = {
        transactionId: 0,
        invoiceNo: "",
        grandTotal: 0,
        saleId: "",
        branchId: 0,
        isReturnInventory: 1,
      };
    },
    async show() {
      this.clearData();
      let getSaleId = this.$cookies.get("saleId");

if (getSaleId && this.require_pin_login == "1") {
  this.form.saleId = getSaleId;
  console.log(this.form.saleId);
  this.isLockSaleId = true;
}
      this.$v.form.$reset();
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async cancelTransaction() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.isLoading = true;
      let body = {
        transaction_id: this.fromInventoryId,
        invoiceNo: this.form.invoiceNo,
        grand_total: this.form.grandTotal,
        sale_id: this.form.saleId,
        branch_id: this.branchId,
        is_return_inventory: this.form.isReturnInventory,
      };
      await this.$store.dispatch("setMainLoading", true);
      await this.axios
        .post(`${this.$baseUrl}/transaction/void_transaction`, body)
        .then((data) => {
          this.isLoading = false;
          this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert(data.message).then(() => {
              this.$router.push("/transaction");
            });
            this.hide();
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async onDataChange(val) {
      this.form.toBranchId = val;
    },
    handleInvoiceNo: function (evt) {
      evt = evt ? evt : window.event;
      if (evt.which === 32) evt.preventDefault();
      else {
        if (this.invoiceLength) {
          evt = evt ? evt : window.event;
          var charCode = evt.which ? evt.which : evt.keyCode;
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
          } else {
            let value = evt.target.value;
            let valueLength = value.length;
            let isnum = /^\d+$/.test(parseInt(evt.key));
            let key = isnum ? evt.key : "";
            let repeatStr = "";
            if (evt.keyCode === 8) {
              value = value.substring(0, value.length - 1);
              if (this.isInvoiceLeadingZero)
                repeatStr = "0".repeat(this.invoiceLength - valueLength + 1);
            } else {
              if (this.isInvoiceLeadingZero)
                repeatStr =
                  valueLength < this.invoiceLength
                    ? "0".repeat(this.invoiceLength - valueLength - 1)
                    : "";
            }

            value = `${repeatStr}${value}${key}`;
            value = value.slice(this.invoiceLength * -1);
            this.form.invoiceNo = value;
            evt.preventDefault();
          }
        } else {
          let thai_text =
            "ๅภถุึคตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝ๑๒๓๔ู฿๕๖๗๘๙๐ฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ";
          if (thai_text.indexOf(evt.key) >= 0) {
            evt.preventDefault();
          }
        }
      }
    },
    handleEnter() {
      const inputElement = this.$refs.saleIdInput.$el.querySelector("input");
      if (inputElement) {
        inputElement.blur();
      }
    },
    async updateSaleId(saleId, pin) {
      this.isLockSaleId = true;
      this.form.saleId = saleId;
      this.$cookies.set("saleId", saleId, { expires: "1d" });
    },
    openModalPin() {
      this.$cookies.remove("saleId");
      this.form.saleId = "";
      this.$refs.ModalPin.showModal = true;
      this.$refs.ModalPin.focusInput();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  color: #fff;
  width: 100%;
  background-color: var(--primary-color);
}
::v-deep .modal-body {
  padding: 1rem 1rem;
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: var(--primary-color);
    background-color: var(--primary-color);
  }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: var(--primary-color);
  }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none !important;
  }
}
.btn-primary .modal-body {
  margin: auto;
  text-align: center;
}
.panel-display-count {
  padding-top: 10px;
  p.label {
    color: #333 !important;
    font-weight: bold !important;
  }
  p {
    color: #999 !important;
    font-size: 14px !important;
  }
}

@media (max-width: 768px) {
  ::v-deep .modal-title {
    text-align: center;
  }
}
</style>

<template>
  <div class="space-bottom">
    <b-tabs content-class="py-3" v-model="tabIndex">
      <b-tab title="หน้าหลัก">
        <b-row class="align-items-center">
          <b-col md="5" lg="4" xl="3" cols="12">
            <div class="d-flex mt-2">
              <p class="mt-2 mr-2 title" style="flex: 1">เลขที่</p>

              <InputText
                type="text"
                className=" bg-second flex-grow-1"
                placeholder="เลขที่"
                textField=""
                :value="form.rqp_no"
                disabled
              />
            </div>
          </b-col>
          <b-col md="5" lg="4" xl="3" cols="12">
            <div class="d-flex mt-2">
              <p class="mt-2 mr-2 title" style="flex: 1">วันที่</p>
              <InputText
                type="text"
                className=" bg-second flex-grow-1 "
                placeholder="วันที่"
                textField=""
                :value="$moment(form.created_time).format('DD/MM/YYYY (HH:mm)')"
                disabled
              />
            </div>
          </b-col>
          <b-col md="6" cols="12" v-if="id != 0" class="mt-3 mt-md-0">
            <div
              class="row no-gutters align-items-center justify-content-md-end justify-content-center"
              style="column-gap: 10px; row-gap: 10px"
            >
              <div class="w-md-unset">
                สถานะ:
                <span :class="{ 'text-danger': form.rqp_status_id == 1 }">{{
                  form.rqp_status_name
                }}</span>
              </div>
              <div>
                อัพเดทล่าสุด:
                {{ $moment(form.updated_time).format("DD/MM/YYYY") }}
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-4">
          <b-col md="12">
            <p class="title">สาขา</p>

            <b-card class="mt-2">
              <InputText
                ref="source-input"
                id="source-input"
                :value="branchName"
                disabled
                type="text"
                className=" mb-2 bg-second w-100"
                textField="branch_name"
              >
              </InputText>

              <div
                class="d-flex mb-2"
                v-for="item in sourceBranch"
                :key="item.label"
              >
                <p class="text-label text-light-gray">{{ item.label }}</p>
                <p class="text-light-gray">:</p>
                <p>&nbsp;{{ item.value || "-" }}</p>
              </div>
            </b-card>
          </b-col>

          <b-col cols="12">
            <InputTextArea
              name="text"
              rows="4"
              v-model="form.note"
              :disabled="isSaved"
              textFloat="หมายเหตุ"
            />
          </b-col>
          <b-col cols="12">
            <p class="title mb-3">รายการสินค้า</p>
            <div class="card p-3">
              <div class="mb-3" v-if="!isSaved">
                <ModalAddProduct @setProduct="setProductSelect" :form="form" />
              </div>

              <b-table
                responsive
                striped
                hover
                show-empty
                empty-text="No matching records found"
                class="table-main table-custom"
                :no-border-collapse="false"
                :busy="isBusy"
                :items="items"
                :fields="fields"
              >
                <template v-slot:cell(index)="{ item }">
                  {{ item.id_sort + 1 }}
                </template>

                <template v-slot:cell(action)="{ item }">
                  <font-awesome-icon
                    v-if="form.rqp_status_id !== 3 || form.rqp_status_id !== 2"
                    icon="trash-alt"
                    title="delete-btn"
                    size="lg"
                    class="pointer text-primary icon-delete"
                    @click.stop="openModalDelete(item.id)"
                  />
                </template>
                <template v-slot:cell(is_approve)="{ item }">
                  <div
                    :class="{
                      'text-success': item.is_approve,
                      'text-danger': !item.is_approve,
                    }"
                  >
                    {{ item.product_action }}
                  </div>
                </template>
                <template v-slot:cell(note)="{ item }">
                  <InputText
                    v-model="item.note"
                    @input="updateProductNote(item.note, item.product_id)"
                    textFloat=""
                    :disabled="isSaved"
                    placeholder="หมายเหตุ"
                    type="text"
                  >
                  </InputText>
                </template>
                <template v-slot:cell(headquarters_note)="{ item }">
                  <InputText
                    v-model="item.headquarters_note"
                    placeholder="หมายเหตุ(HQ)"
                    textFloat=""
                    :disabled="isSaved"
                    type="text"
                  >
                  </InputText>
                </template>

                <template v-slot:cell(rqp_action_ref)="{ item }">
                  <div v-if="!item.rqp_action_ref_id">
                    {{ item.rqp_action_ref || "-" }}
                  </div>
                  <a
                    v-else
                    class="text-underline"
                    :href="`/inventory-transfer/16/detail/${item.rqp_action_ref_id}`"
                  >
                    {{ item.rqp_action_ref }}
                  </a>
                </template>
                <template v-slot:cell(product_image)="{ item }">
                  <img
                    :src="item.product_image"
                    class="img-size"
                    v-if="item.product_image"
                  />
                  <img
                    v-else
                    src="@/assets/images/noimg.png"
                    alt="no-image-product"
                    class="img-size"
                  />
                </template>
                <template v-slot:cell(quantity)="{ item }">
                  <InputText
                    @input="updateProductQ(item.quantity, item.product_id)"
                    v-model="item.quantity"
                    textFloat=""
                    :disabled="isSaved"
                    type="number"
                  >
                  </InputText>
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
              <Pagination
                @handleChangeTake="handleChangeTake"
                :pageOptions="pageOptions"
                :filter="filter"
                :rows="rows"
                @pagination="pagination"
              />
            </div>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab title="ประวัติ" :disabled="id == 0">
        <TableHistory />
      </b-tab>
    </b-tabs>
    <FooterAction
      :routePath="'/request-purchase'"
      @submit="confirmAlertModal()"
      @customAction="confirmAlertModal(true)"
      :textCustom="(id != 0 && !isSaved) || id == 0 ? 'บันทึกฉบับร่าง' : null"
      :hideSubmit="isSaved"
    ></FooterAction>
    <ModalAlertConfirm
      ref="modalAlertConfirm"
      :msg="confirmMessage.msg"
      :text="confirmMessage.text"
      @confirm="(val) => saveForm(val)"
    />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import InputTextArea from "../../../components/inputs/InputTextArea.vue";
import Pagination from "@/components/Pagination";
import FooterAction from "@/components/FooterAction";

import TableHistory from "./components/TableHistory";
import { mapGetters } from "vuex";
import ModalAlertConfirm from "@/components/modal/alert/ModalAlertConfirm";
import ModalAddProduct from "./components/ModalAddProduct";
export default {
  components: {
    InputText,
    InputTextArea,
    Pagination,
    FooterAction,
    TableHistory,
    ModalAlertConfirm,
    ModalAddProduct,
  },
  data() {
    return {
      fields: [
        { label: "#", key: "index" },
        { label: "Image", key: "product_image", thStyle: { width: "10%" } },
        { label: "Barcode", key: "barcode" },
        {
          label: "Name",
          key: "product_name",
          tdClass: "text-left",
          thStyle: { width: "30%" },
        },
        { label: "หมายเหตุ(สาขา)", key: "note", thStyle: { width: "10%" } },
        // {
        //   label: "หมายเหตุ(HQ)",
        //   key: "headquarters_note",
        //   thStyle: { width: "10%" },
        // },
        { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },
        // {
        //   label: "สถานะ",
        //   key: "is_approve",
        //   thStyle: { width: "10%" },
        // },
        // {
        //   label: "หมายเลขใบโอน",
        //   key: "rqp_action_ref",
        //   thStyle: { width: "10%" },
        // },
        { label: "Action", key: "action", thStyle: { width: "10%" } },
      ],
      tabIndex: 0,
      isBusy: false,

      filter: {
        search: "",
        page: 1,
        take: 10,
      },
      sourceBranch: [
        {
          label: "ชื่อสาขา",
          value: "",
        },
        { label: "รหัส", value: "" },
        { label: "ประเภท", value: "" },
      ],

      productList: [],

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items: [],
      rows: 0,
      id: this.$route.params.id,
      form: {
        rqp_id: 0,
        rqp_no: "",
        rqp_status_id: 0,
        rqp_status_name: "",
        request_branch_id: null,
        request_branch_name: "",
        branch_type_name: "",
        request_branch_code: "",
        note: "",

        created_by: "",
        updated_by: "",
        updated_time: "",
        products: [],
      },
      isDraft: false,
      allItem: [],
      filteredItems: [],
      searchDisplayProduct: "",
      documentDetail: { destination_branches: [], source_branches: [] },
      modalCallBack: () => {},
      modalMessage: "",
      isSaved: false,
      confirmMessage: {
        text: "",
        msg: "",
      },
    };
  },
  created() {
    if (this.$route.params.detail_id != 0) this.getDetail();

    this.sourceBranch = [
      {
        label: "ชื่อสาขา",
        value: this.branchName,
      },
      { label: "รหัส", value: this.plantId },
      { label: "ประเภท", value: this.branchType },
    ];
  },

  computed: {
    ...mapGetters({
      mainLoading: "getMainLoading",
      branchId: "getBranchId",
      plantId: "getPlantId",
      branchName: "getBranchName",
      branchType: "getBranchType",
    }),
  },

  methods: {
    async getDetail() {
      if (this.id == 0) return;
      await this.$store.dispatch("setMainLoading", true);
      this.isBusy = true;
      const res = await this.axios(`${this.$baseUrl}/rqp/${this.id}`);
      if (res.result == 0) {
        await this.$store.dispatch("setMainLoading", false);
        this.errorAlert(res.message).then(() => {
          this.$router.replace("/request-purchase/");
        });
        return;
      }
      this.form = res.detail;
      if (this.form.rqp_status_id > 1) {
        this.fields = [
          { label: "#", key: "index" },
          { label: "Image", key: "product_image", thStyle: { width: "10%" } },
          { label: "Barcode", key: "barcode" },
          {
            label: "Name",
            key: "product_name",
            tdClass: "text-left",
            thStyle: { width: "30%" },
          },
          { label: "หมายเหตุ(สาขา)", key: "note", thStyle: { width: "10%" } },
          {
            label: "หมายเหตุ(HQ)",
            key: "headquarters_note",
            thStyle: { width: "10%" },
          },
          { label: "Quantity", key: "quantity", thStyle: { width: "10%" } },
          {
            label: "สถานะ",
            key: "is_approve",
            thStyle: { width: "10%" },
          },
          {
            label: "หมายเลขใบโอน",
            key: "rqp_action_ref",
            thStyle: { width: "10%" },
          },
        ];
      }

      this.items = res.detail.products.map((product, index) => ({
        ...product,
        id_sort: index,
      }));

      this.allItem = this.items;
      if (res.detail.rqp_status_id > 1) {
        this.isSaved = true;
      }

      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
      this.updateDisplayedItems();
      this.isBusy = false;
      await this.$store.dispatch("setMainLoading", false);
      if (this.$route.query.tabIndex) this.tabIndex = 1;
    },

    async saveForm() {
      let products = this.allItem.map((product) => ({
        id: product.id || 0,
        product_id: product.product_id,
        quantity: product.quantity,
        note: product.note || "",
      }));
      await this.$store.dispatch("setMainLoading", true);

      let payload = {
        rqp_id: this.id,
        is_draft: this.isDraft,
        note: this.form.note,
        products: [...products],
      };
      this.axios
        .post(`${this.$baseUrl}/rqp/save`, payload)
        .then(async (data) => {
          await this.$store.dispatch("setMainLoading", false);
          if (data.result == 1) {
            this.successAlert();
            this.$router
              .push({
                path: `/request-purchase`,
              })
              .catch(() => {});
          } else {
            this.warningAlert(data.message);
          }
        });
    },

    setProductSelect(product) {
      const productIndex = this.allItem.findIndex(
        (item) => item.product_id === product.product_id
      );

      if (productIndex !== -1) {
        this.allItem[productIndex].quantity += 1;
      } else {
        let _product = {
          product_image: product.product_image,
          barcode: product.barcode,
          product_name: product.product_name,
          quantity: 1,

          headquarters_note: product.headquarters_note,
          note: product.note,
          rqp_action_ref: product.rqp_action_ref,
          product_id: product.product_id,
          id: product.id || 0,
          id_sort: this.allItem.length,
        };

        this.allItem.push({ ..._product });
        this.filter.page = 1;
        this.updateDisplayedItems();
      }

      const maxItems = this.filter.take;

      if (this.allItem.length > maxItems) {
        this.items = this.allItem.slice(0, maxItems);
      } else {
        this.items = this.allItem;
      }
      this.rows = this.allItem.length;
      this.filteredItems = this.allItem;
    },

    openModalDelete(id) {
      this.confirmAlert("คุณต้องการลบสินค้าชิ้นนี้หรือไม่ ?", "ยืนยัน").then(
        (val) => {
          if (val.isConfirmed) {
            this.deleteProduct(id);
          }
        }
      );
    },
    deleteProduct(item) {
      this.allItem = this.allItem
        .filter((x) => x.id !== item)
        .map((el, index) => {
          return { ...el, id: index };
        });

      this.filteredItems = this.filteredItems
        .filter((x, index) => x.id !== item)
        .map((el, index) => {
          return { ...el, id: index };
        });
      this.updateDisplayedItems(true);
    },
    handleSearchDisplayProduct() {
      const searchText = this.searchDisplayProduct.toLowerCase();
      if (searchText) {
        this.filteredItems = this.allItem.filter(
          (item) =>
            item.product_name.toLowerCase().includes(searchText) ||
            item.barcode.toLowerCase().includes(searchText)
        );
      } else {
        this.filteredItems = [...this.allItem];
      }
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    pagination(val) {
      this.filter.page = val;
      this.updateDisplayedItems();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.updateDisplayedItems();
    },
    async updateDisplayedItems(isDelete) {
      this.isBusy = true;
      if (isDelete) {
        this.filter.page = 1;
      }
      const start = (this.filter.page - 1) * this.filter.take;
      const end = start + this.filter.take;

      this.items = this.allItem
        .map((el, index) => {
          return { ...el, id: index };
        })
        .slice(start, end);

      this.rows = this.allItem.length;
      await this.$nextTick();
      this.isBusy = false;
    },
    async selectSerial(product, index) {
      this.$refs.modalSerialList.show(product, index);
    },
    updateProductNote(val, id) {
      const item = this.allItem.find((item) => item.product_id === id);
      if (item) {
        item.note = val;
      }
    },
    updateProductQ(val, id) {
      const item = this.allItem.find((item) => item.product_id === id);
      if (item) {
        item.quantity = val;
      }
    },

    // async exportDetail() {
    //   await this.$store.dispatch("setMainLoading", true);
    //   const res = await this.axios(
    //     `${this.$baseUrl}/Inventory/Transfer/${this.id}/export`,

    //     {
    //       responseType: "blob",
    //     }
    //   );

    //   let data = res;

    //   var fileURL = window.URL.createObjectURL(new Blob([data]));
    //   var fileLink = document.createElement("a");

    //   fileLink.href = fileURL;
    //   fileLink.setAttribute(
    //     "download",
    //     `${this.form.document_no || "Draft"}-${this.$moment().format(
    //       "DD/MM/YYYY_HH:MM:ss"
    //     )}(${this.form.inventory_transfer_status_name}).xlsx`
    //   );
    //   document.body.appendChild(fileLink);
    //   fileLink.click();
    //   await this.$store.dispatch("setMainLoading", false);
    // },
    confirmAlertModal(isDraft = false) {
      this.$nextTick();

      if (this.allItem.length == 0) return this.errorAlert("กรุณาเลือกสินค้า");
      this.isDraft = isDraft;
      if (isDraft)
        this.confirmMessage = {
          msg: "ยืนยันการบันทึกฉบับร่าง",
        };
      else
        this.confirmMessage = {
          msg: "ยืนยันการทำรายการ",
          text: "หลังจากยืนยันแล้วจะไม่สามารถแก้ไขได้",
        };
      if (isDraft && this.$route.params.detail_id == 0) {
        this.$refs.modalAlertConfirm.show(isDraft);
      } else if (isDraft && this.$route.params.detail_id != 0) this.saveForm();
      else this.$refs.modalAlertConfirm.show(isDraft);
    },
  },
};
</script>
<style lang="scss" scoped>
.w-85 {
  width: 85%;
}

.title {
  color: #333 !important;
  font-size: 16x !important;
  font-weight: bold !important;
  position: relative;
}

.text-label {
  width: 100px;
}

.text-light-gray {
  color: #9b9b9b;
}

::v-deep .input-group-text {
  background-color: var(--primary-color) !important;
  color: white !important;
}

// .custom-add-product ::v-deep .input-custom input[data-v-32b8d6e1] {
//   border-radius: 0px !important;
// }

.custom-height-bt {
  height: 38.66px !important;
}

.img-size {
  width: 60px;
  height: 60px;
}

// ::v-deep .c-main > .container-fluid {
//   height: calc(100vh - 80px) !important;
//   overflow: scroll !important;
// }
.space-bottom {
  padding-bottom: 80px;
}
</style>

import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Login = () => import("@/views/pages/Login");
const HomeIndex = () => import("@/views/pages/home/Index");
const ReportIndex = () => import("@/views/pages/report/Index");
const PointIndex = () => import("@/views/pages/point/Index");
const CustomerIndex = () => import("@/views/pages/customer/Index");
const NewCustomer = () => import("@/views/pages/customer/register/NewCustomer");
const RegisterSuccess = () => import("@/views/pages/customer/register/Success");
const ProspectIndex = () => import("@/views/pages/customer/prospect/Index");
const PrivilegedIndex = () => import("@/views/pages/customer/privileged/Index");
const CreateSaleOrderIndex = () =>
  import("@/views/pages/saleOrder/CreateSaleOrder");
const SaleOrderIndex = () => import("@/views/pages/saleOrder/Index");
const SaleOrderDetail = () => import("@/views/pages/saleOrder/Detail");
const EmployeeIndex = () => import("@/views/pages/employee/Index");
const RedeemIndex = () => import("@/views/pages/redeem/Index");
const MissionIndex = () => import("@/views/pages/mission/Index");
// const RedeemHistoryIndex = () => import("@/views/pages/redeem/History");
// const RedeemHistoryDetail = () => import("@/views/pages/redeem/HistoryDetail");
const InventoryIndex = () => import("@/views/pages/inventory/Index");
const RequestPurchaseIndex = () => import("@/views/pages/requestPurchase/Index.vue");
const RequestPurchaseDetail = () => import("@/views/pages/requestPurchase/detail");
const ReportPointBalanceIndex = () =>
  import("@/views/pages/report/pointBalance/Index");
const ReportRefSaleMemberIndex = () =>
  import("@/views/pages/report/refSaleMember/Index");
const ReportSpaUsageHistoryIndex = () =>
  import("@/views/pages/report/spaUsageHistory/Index");
const ReportOutstandingIndex = () =>
  import("@/views/pages/report/outstanding/Index");
const ReportHistorySaleCategoryIndex = () =>
  import("@/views/pages/report/historySaleCategory/Index");
const TransactionIndex = () => import("@/views/pages/transaction/Index");
const TransactionDetail = () => import("@/views/pages/transaction/Detail");
const TransactionErrorIndex = () =>
  import("@/views/pages/transactionError/Index");
const TransactionErrorDetail = () =>
  import("@/views/pages/transactionError/Detail");
const SummaryIndex = () => import("@/views/pages/summary/Index");
const SpaIndex = () => import("@/views/pages/spa/Index");
const Register = () => import("@/views/pages/register/Index");
const TimeChange = () => import("@/views/pages/change-time/Index");
const InventoryTransfer = () => import("@/views/pages/inventoryTransfer");
const InventoryTransferDetail = () =>
  import("@/views/pages/inventoryTransfer/detail");

Vue.use(Router);

export default new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/account/*",
      name: "",

      meta: { requiresIsLogin: true },
      redirect: "/",
    },
    {
      path: "*",
      name: "Page404",
      redirect: "/",
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { requiresIsLogin: true },
    },
    {
      path: "/customer/new-customer",
      name: "ลูกค้าใหม่",
      component: NewCustomer,
      meta: {
        requiresAuth: true,
        isChildren: true,
        activeUrl: "/customer",
        lv: 2,
      },
    },
    {
      path: "/customer/success",
      name: "ลงทะเบียนสำเร็จ",
      component: RegisterSuccess,
      meta: {
        requiresAuth: true,
        isChildren: true,
        activeUrl: "/customer",
        lv: 2,
      },
    },
    {
      path: "/Customer/NewCustomer",
      name: "ลูกค้าใหม่",
      component: NewCustomer,
      meta: {
        // requiresAuth: true,
        isChildren: true,
        activeUrl: "/customer",
        lv: 2,
      },
    },
    {
      path: "/",
      redirect: "/home",
      name: "หน้าแรก",
      component: TheContainer,
      meta: { requiresAuth: true, lv: 1, activeUrl: "/home" },
      children: [
        {
          path: "home",
          redirect: "/home",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "หน้าแรก",
              component: HomeIndex,
              meta: {
                requiresAuth: true,
                isFullScreen: true,
                isChildren: true,
                activeUrl: "/home",
                lv: 2,
              },
            },
          ],
        },

        {
          path: "/register",
          name: "ลงทะเบียน",
          component: Register,
          meta: {
            requiresAuth: true,
            lv: 1,
            activeUrl: "/register",
          },
        },
        {
          path: "/time",
          name: "เปลี่ยนเวลา",
          component: TimeChange,
          meta: {
            requiresAuth: true,
            lv: 1,
            activeUrl: "/time",
          },
        },

        {
          path: "spa",
          redirect: "/spa",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "หน้าแรก",
              component: SpaIndex,
              meta: {
                requiresAuth: true,
                isFullScreen: true,
                isChildren: true,
                activeUrl: "/spa",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "report",
          redirect: "/report",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "point-balance",
              name: "เช็คคะแนน",
              component: ReportPointBalanceIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
            {
              path: "ref-sale-member",
              name: "ประวัติการซื้อ",
              component: ReportRefSaleMemberIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
            {
              path: "spa-usage-history",
              name: "ประวัติการใช้",
              component: ReportSpaUsageHistoryIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
            {
              path: "outstanding",
              name: "Outstanding",
              component: ReportOutstandingIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
            {
              path: "history-sale-category",
              name: "ประวัติการซื้อแบ่งตามประเภทสินค้า",
              component: ReportHistorySaleCategoryIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
            {
              path: "",
              name: "ประวัติการสั่งซื้อ",
              component: ReportIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/report",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "point",
          redirect: "/point",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "ประวัติคะแนนสะสม",
              component: PointIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/point",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "customer",
          redirect: "/customer",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "privileged",
              name: "ลูกค้าที่ได้รับสิทธิพิเศษ (Customer Privileged)",
              component: PrivilegedIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/customer",
                lv: 2,
              },
            },
            {
              path: "prospect",
              name: "ลูกค้าที่ไม่มีประวัติการซื้อ (Prospect)",
              component: ProspectIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/customer",
                lv: 2,
              },
            },
            {
              path: "purchase-history",
              name: "ลูกค้าที่มีประวัติการซื้อ",
              component: CustomerIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/customer",
                lv: 2,
              },
            },
            {
              path: "",
              name: "ลูกค้า",
              component: CustomerIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/customer",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "create-sale-order",
          redirect: "/create-sale-order",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "สร้างรายการขาย",
              component: CreateSaleOrderIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/create-sale-order",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "sale-order",
          redirect: "/sale-order",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "รายการการขาย",
              component: SaleOrderIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/sale-order",
                lv: 2,
              },
            },
            {
              path: "detail/:id",
              name: "รายละเอียดการขาย",
              component: SaleOrderDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/sale-order",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "employee",
          redirect: "/employee",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "info",
              name: "ข้อมูลพนักงาน",
              component: EmployeeIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/employee",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "redeem",
          redirect: "/redeem",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "แลกของรางวัล",
              component: RedeemIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/redeem",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "mission",
          redirect: "/mission",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Membership Activity",
              component: MissionIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/redeem",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "inventory",
          redirect: "/inventory",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "จัดการคลังสินค้า",
              component: InventoryIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/inventory",
                lv: 2,
              },
            },
          ],
        },
         {
          path: "request-purchase",
          redirect: "/request-purchase",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "สั่งผลิตสินค้าเพื่อเติมสต็อก",
              component: RequestPurchaseIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/request-purchase",
                lv: 2,
              },
            },
            {
              path: "detail/:id",
              name: "สั่งผลิตสินค้าเพื่อเติมสต็อก",
              component: RequestPurchaseDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/request-purchase",
                lv: 3,
              },
            },
          ],
        },
        {
          path: "transaction",
          redirect: "/transaction",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "รายการขาย",
              component: TransactionIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/transaction",
                lv: 2,
              },
            },
            {
              path: "detail/:id/:userGuid/:showOther",
              name: "รายละเอียดรายการขาย",
              component: TransactionDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/transaction",
                lv: 2,
              },
            },
            {
              path: "detail/:id/:userGuid",
              name: "รายละเอียดรายการขาย",
              component: TransactionDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/transaction",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "transaction-error",
          redirect: "/transaction-error",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "รายการขาย",
              component: TransactionErrorIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/transaction-error",
                lv: 2,
              },
            },
            {
              path: "detail/:id/:userGuid/:showOther",
              name: "รายละเอียดรายการขาย",
              component: TransactionErrorDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/transaction",
                lv: 2,
              },
            },
            {
              path: "detail/:id/:userGuid",
              name: "รายละเอียดรายการขาย",
              component: TransactionErrorDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                isFullScreen: true,
                activeUrl: "/transaction-error",
                lv: 2,
              },
            },
          ],
        },
        {
          path: "summary",
          redirect: "/summary",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "สรุปยอดขายประจำเดือน",
              component: SummaryIndex,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/summary",
                lv: 2,
                isFullScreen: true,
              },
            },
          ],
        },
        {
          path: "inventory-transfer/:id",
          redirect: "/inventory-transfer",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "ข้อมูลพนักงาน",
              component: InventoryTransfer,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/inventory-transfer",
                lv: 2,
              },
            },
            {
              path: "detail/:detail_id",
              name: "ข้อมูลพนักงาน",
              component: InventoryTransferDetail,
              meta: {
                requiresAuth: true,
                isChildren: true,
                activeUrl: "/inventory-transfer",
                lv: 2,
              },
            },
          ],
        },
      ],
    },
  ],
});

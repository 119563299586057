import Vue from "vue";
import Vuex from "vuex";
import profile from "./modules/profile";
import theme from "./modules/theme";
import global from "./modules/global";
import saleOrder from "./modules/saleOrder";
import redeem from "./modules/redeem";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    profile,
    theme,
    global,
    saleOrder,
    redeem,
  },
  state: {
    sidebarShow: false,
    slidebarCustom: false,
    slidebarCurrent: 1,
    isCenterPoint: 1,
    colorPrimary: "",
    colorSecondary: "",
    backgroundPrimary: "",
    backgroundSecondary: "",
    logoHomePage: "",
    sidebarMinimize: false,
    asideShow: false,
    darkMode: false,
    globalLanguages: [],
    email: "",
    timestamp: null,
    userData: {
      token: null,
      user: null,
      brands: null,
      permissions: null,
    },
    timestamp: null,
    currentBrandID: null,
    brandList: null,
    brandDetail: null,
    permission: null,
    loading: {
      show: true,
      progress: 0,
    },
    showDropdownBrand: true,
    limit_secondary_telephone: null,
    require_pin_login: null,
    time_lock_pin: null,
  },
  mutations: {
    UPDATE_IS_CENTER_POINT: (state, payload) => {
      state.isCenterPoint = payload;
    },
    UPDATE_LIMIT_SECONDARY_TELEPHONE: (state, payload) => {
      Vue.set(state, "limit_secondary_telephone", payload);

    },
    toggleSidebarDesktop(state) {
      const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarOpened ? false : "responsive";
    },
    toggleSidebarMobile(state) {
      const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
      state.sidebarShow = sidebarClosed ? true : "responsive";
    },
    toggleSidebarCustom(state) {
      state.slidebarCustom = !state.slidebarCustom;
    },
    set(state, [variable, value]) {
      state[variable] = value;
    },
    toggle(state, variable) {
      Vue.set(state, variable, !state[variable]);
    },
    setGlobalLanguages(state, list) {
      list.forEach((item) => {
        state.globalLanguages.push({ ...item });
      });
    },
    setEmail(state, val) {
      state.email = val;
    },
    UPDATE_TIMESTAMP: (state, payload) => {
      Vue.set(state, "timestamp", payload);
    },
    UPDATE_USERDATA: (state, payload) => {
      Vue.set(state, "userData", payload);
    },
    UPDATE_PERMISSION: (state, payload) => {
      Vue.set(state, "permission", payload);
    },
    UPDATE_BRANDDETAIL: (state, payload) => {
      Vue.set(state, "brandDetail", payload);
    },
    UPDATE_BRANDLIST: (state, payload) => {
      Vue.set(state, "brandList", payload);
    },
    UPDATE_CURRENT_BRANDID: (state, payload) => {
      var brandDetail = state.brandList.find((item) => item.id == payload);
      Vue.set(state, "currentBrandID", payload);
      Vue.set(state, "brandDetail", brandDetail);
    },
    UPDATE_DATA_PROGRESS: (state) => {
      var progress = 0;

      // if (state.userData != null) progress += 20;
      if (state.userData != null) progress += 100;
      if (state.currentBrandID != null) progress += 30;
      if (state.brandList != null) progress += 20;
      if (state.brandDetail != null) progress += 30;
      Vue.set(state.loading, "progress", progress);
      if (progress == 100) {
        Vue.set(state.loading, "show", false);
      } else {
        Vue.set(state.loading, "show", true);
      }
    },
    UPDATE_SHOWDROPDOWNBRAND: (state, payload) => {
      state.showDropdownBrand = payload;
    },
    UPDATE_TIMESTAMP: (state, payload) => {
      Vue.set(state, "timestamp", payload);
    },
    UPDATE_COLORPRIMARY: (state, payload) => {
      Vue.set(state, "colorPrimary", payload);
    },
    UPDATE_COLORSECONDARY: (state, payload) => {
      Vue.set(state, "colorSecondary", payload);
    },
    UPDATE_BACKGROUNDPRIMARY: (state, payload) => {
      Vue.set(state, "backgroundPrimary", payload);
    },
    UPDATE_BACKGROUNDSECONDARY: (state, payload) => {
      Vue.set(state, "backgroundSecondary", payload);
    },
    UPDATE_LOGOHOMEPAGE: (state, payload) => {
      Vue.set(state, "logoHomePage", payload);
    },
    UPDATE_SLIDEBARCURRENT: (state, payload) => {
      Vue.set(state, "slidebarCurrent", payload);
    },
    UPDATE_IS_REQUIRE_PIN: (state, payload) => {
      Vue.set(state, "require_pin_login", payload);
    },
    UPDATE_TIME_LOCK_PIN: (state, payload) => {
      Vue.set(state, "time_lock_pin", payload);
    },
  },
  actions: {
    setIsCenterPoint(context, payload) {
      context.commit("UPDATE_IS_CENTER_POINT", payload);
    },
    setLimitSecondaryTelephone(context, payload) {
      context.commit("UPDATE_LIMIT_SECONDARY_TELEPHONE", payload);
    },
    setUserData(context, payload) {
      context.commit("UPDATE_USERDATA", payload);
    },
    deleteUserData(context) {
      context.commit("UPDATE_USERDATA", {});
    },
    setPermission(context, payload) {
      context.commit("UPDATE_PERMISSION", payload);
    },
    setBrandDetail(context, payload) {
      context.commit("UPDATE_BRANDDETAIL", payload);
    },
    setBrandList(context, payload) {
      context.commit("UPDATE_BRANDLIST", payload);
    },
    setCurrentBrandID(context, payload) {
      context.commit("UPDATE_CURRENT_BRANDID", payload);
    },
    updateDataProgress(context) {
      context.commit("UPDATE_DATA_PROGRESS");
    },
    setShowDropdownBrand(context, payload) {
      context.commit("UPDATE_SHOWDROPDOWNBRAND", payload);
    },
    setTimestamp(context, payload) {
      context.commit("UPDATE_TIMESTAMP", payload);
    },
    setColorPrimary(context, payload) {
      context.commit("UPDATE_COLORPRIMARY", payload);
    },
    setColorSecondary(context, payload) {
      context.commit("UPDATE_COLORSECONDARY", payload);
    },
    setBackgroundPrimary(context, payload) {
      context.commit("UPDATE_BACKGROUNDPRIMARY", payload);
    },
    setBackgroundSecondary(context, payload) {
      context.commit("UPDATE_BACKGROUNDSECONDARY", payload);
    },
    setLoGoHomePage(context, payload) {
      context.commit("UPDATE_LOGOHOMEPAGE", payload);
    },
    setSlideBarCurrent(context, payload) {
      context.commit("UPDATE_SLIDEBARCURRENT", payload);
    },
    setIsRequirePin(context, payload) {
      context.commit("UPDATE_IS_REQUIRE_PIN", payload);
    },
    setTimeLockPin(context, payload) {
      context.commit("UPDATE_TIME_LOCK_PIN", payload);
    },
  },
  getters: {
    getIsCenterPoint: (state) => {
      return state.isCenterPoint;
    },
    getUserData: (state) => () => {
      return state.userData;
    },
    getUserDetail: (state) => () => {
      return state.userData;
    },
    getUserBrandsIDList: (state) => () => {
      return state.userData.brands;
    },
    getUserBrandsDetailList: (state) => () => {
      return state.userData.brands;
    },
    getUserBrandsDetailByID: (state) => (brandID) => {
      return state.userData.brands.find((item) => item.brandID == brandID);
    },
    getUserPermissionsList: (state) => () => {
      return state.userData.permissions;
    },
    getUserPermissionsByBrandID: (state) => (brandID) => {
      return state.userData.permissions.find((item) => item.brandID == brandID)
        .permission;
    },
    getPermission: (state) => () => {
      return state.permission;
    },
    getBrandDetail: (state) => () => {
      return state.brandDetail;
    },
    getBrandList: (state) => () => {
      return state.brandList;
    },
    getCurrentBrandID: (state) => () => {
      return state.currentBrandID;
    },
    ishavePermission: (state) => (userPermisstion) => {
      if (userPermisstion == -1 || state.permission == null) {
        return true;
      } else {
        return userPermisstion.some((r) => state.permission.includes(r));
      }
    },
    getDataLoadingProgress: (state) => () => {
      return state.loading.progress;
    },
    getDataLoadingProgressByData: (state) => () => {
      return {
        userData: state.userData != null,
        currentBrandID: state.currentBrandID != null,
        brandList: state.brandList != null,
        brandDetail: state.brandDetail != null,
        permission: state.permission != null,
      };
    },
    isLoading: (state) => () => {
      return state.loading.show;
    },
    getShowDropdownBrand: (state) => {
      return state.showDropdownBrand;
    },
    getTimestamp: (state) => () => {
      return state.timestamp;
    },
    getRequirePin: (state) => {
      return state.require_pin_login;
    },
    getTimeLockPin: (state) => {
      return state.time_lock_pin;
    },
  },

});

// const state = {
//   sidebarShow: "responsive",
//   sidebarMinimize: false,
//   asideShow: false,
//   darkMode: false,
//   globalLanguages: [],
//   email: ""
// };

// const mutations = {
//   toggleSidebarDesktop(state) {
//     const sidebarOpened = [true, "responsive"].includes(state.sidebarShow);
//     state.sidebarShow = sidebarOpened ? false : "responsive";
//   },
//   toggleSidebarMobile(state) {
//     const sidebarClosed = [false, "responsive"].includes(state.sidebarShow);
//     state.sidebarShow = sidebarClosed ? true : "responsive";
//   },
//   set(state, [variable, value]) {
//     state[variable] = value;
//   },
//   toggle(state, variable) {
//     Vue.set(state, variable, !state[variable]);
//   },
//   setGlobalLanguages(state, list) {
//     list.forEach(item => {
//       state.globalLanguages.push({ ...item });
//     });
//   },
//   setEmail(state, val) {
//     state.email = val;
//   }
// };

// export default new Vuex.Store({
//   state,
//   mutations
// });

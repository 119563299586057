<template>
  <div>
    <b-sidebar
      v-model="showModal"
      backdrop
      shadow
      backdrop-variant="dark"
      right
      aria-labelledby="sidebar-customer-List-title"
      @hidden="hide()"
      no-header
      no-close-on-backdrop
    >
      <template>
        <div
          class="sidebar-content"
          id="sidebar-customer-List"
          @scroll="loadMore"
        >
          <div class="filter px-2">
            <div class="d-flex justify-content-between align-items-center py-3">
              <label class="header-text">ค้นหาลูกค้า</label>
              <div class="pointer" @click="hide()">
                <b-icon icon="x-circle-fill"></b-icon>
              </div>
            </div>
            <div class="d-flex justify-content-start align-items-end filter">
              <InputText
                v-model="filter.search"
                textFloat=""
                type="text"
                name="search_customer"
                faIcon="search"
                className="mb-2 w-100"
                @onEnter="handleSearch"
                @onClickIcon="handleSearch"
                :disabled="isLoading"
              />
              <div>
                <img
                  src="@/assets/images/icons/bin.png"
                  alt="delete"
                  class="pointer delete-icon"
                  center
                  width="32"
                  @click="clearSearch()"
                />
              </div>
            </div>
          </div>

          <div class="customer-list pb-3">
            <div
              class="p-2 my-2 bg-white pointer customer-item"
              v-for="(c, index) in items"
              :key="index"
              @click="selectRow(c)"
            >
              <div class="d-flex justify-content-between">
                <p class="text-m-16">{{ c.member_tier }}</p>
                <p>
                  <label class="text-m-16"
                    >{{ c.first_name_th }} {{ c.last_name_th }}</label
                  >
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-r-14">{{ c.member_id }}</p>
                <p class="text-r-14">
                  {{ (c.point || c.center_point).toLocaleString() }}
                  คะแนน
                </p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text-r-14">{{ c.telephone }}</p>
                <p class="text-r-14">
                  {{ new Date(c.created_time) | moment($formatDate) }}
                </p>
              </div>
            </div>
            <div v-if="isLoading" class="loading-panel">
              <OtherLoading />
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import InputText from "@/components/inputs/InputText";
import { mapGetters } from "vuex";

export default {
  name: "SlideCustomerList",
  components: { OtherLoading, InputText },
  props: {
    redeemId: {
      type: [String, Number],
      default: 0,
    },
  },

  data() {
    return {
      isLoading: false,
      showModal: false,
      filter: {
        search: "",
        datetime: "",
        branchId: "",
        page: 1,
        take: 20,
        redeemId: undefined,
      },
      now: null,
      items: [],
      listWrapper: null,
      rows: 0,
      isDelete: false,
    };
  },
  computed: {
    ...mapGetters({
      dateTimeFix: "getDateTimeFix",
      branchId: "getBranchId",
    }),
    isLastPage() {
      return this.items.length === this.rows;
    },
  },
  created: async function () {
    this.now = this.$moment().format("YYYY-MM-DDTHH:mm:ss");
  },
  mounted() {},
  methods: {
    clearSearch() {
      if (!this.isLoading) {
        this.filter.search = "";
        this.items = [];
        this.getList();
        this.isDelete = true;
      }
    },
    loadMore(e) {
      const masonry = document.querySelector("#sidebar-customer-List");

      if (
        masonry.scrollTop + masonry.clientHeight >=
        masonry.scrollHeight - 20
      ) {
        if (this.isLoading || this.isLastPage) return;
        this.filter.page += 1;
        this.getList();
      }
    },
    getList: async function () {
      this.isBusy = true;
      this.filter.redeemId = this.redeemId ? this.redeemId : undefined;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;
      payload.branch_id = this.branchId;
      payload.branchId = this.branchId;
      payload.datetime =
        this.dateTimeFix || this.$moment().format("YYYY-MM-DDTHH:mm:ss");

      this.isLoading = true;
      await this.axios
        .post(`${this.$baseUrl}/customer/searchuser`, payload)
        .then(async (data) => {
          if (data.result == 1) {
            this.items = this.items.concat(data.detail);
            this.rows = data.total_count;
          }
        });
      if (this.items.length == 0) {
        this.$emit("searchNotFound");
      }
      this.isLoading = false;
    },
    async getCustomers(search) {
      this.filter.search = search;
      await this.pagination(1);
      return this.items;
    },
    async show(search) {
      this.filter.search = search;
      this.showModal = true;
      await this.pagination(1);
      if (this.rows == 1) {
        await this.setUserSelect(this.items[0]);
        await this.hide();
      }
    },
    hide() {
      this.showModal = false;
      this.rows = 0;
      this.items = [];
    },

    pagination(page) {
      this.filter.page = page;
      this.getList();
    },

    handleSearch(search) {
      if (!this.isLoading) {
        this.filter.search = search;
        this.items = [];
        this.pagination(1);
      }
    },
    async selectRow(item) {
      if (!item.is_active) {
        await this.hide();
        return this.warningAlert(
          `ไม่สามารถเลือกลูกค้าได้ เนื่องจากถูกปิดการใช้งาน`
        );
      } else if (item.valid_user) {
        await this.setUserSelect(item);
        await this.hide();
      } else {
        let created_time = this.$moment(item.created_time)
          .add(543, "years")
          .format("DD/MM/YYYY HH:mm");
        this.warningAlert(
          `ไม่สามารถเลือกลูกค้าที่ลงทะเบียนในเวลา ${created_time} ได้`
        );
      }
    },
    setUserSelect(item) {
      this.$emit("setUserSelect", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-card {
  background-color: white;
  border-radius: 8px;
  p {
    padding: 0px;
  }
}
.customer-card:hover {
  background-color: #d1d1d1;
}

.sidebar-content {
  height: calc(100vh - 9px);
  display: flex;
  flex-direction: column;
  overflow: auto;

  .filter {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #ebe5e5;
  }

  .customer-list {
    flex: 1;

    .customer-item {
      border-bottom: 1px solid #ebe5e5;
    }

    .loading-panel {
      height: 50px;
      div {
        height: 100%;
      }
    }
  }
}

.delete-icon {
  margin-bottom: 11px;
  margin-left: 8px;
}

::v-deep .b-sidebar > .b-sidebar-body {
  overflow: hidden;
}

::v-deep .b-sidebar {
  width: 90vw;
}
</style>

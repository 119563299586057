<template>
  <div>
    <div v-if="getUserDetail()">
      <b-dropdown
        id="dropdown-1"
        class="my-md-2 btn-brand-selected small-dropdown"
        right
        no-flip
      >
        <template v-slot:button-content>
          <div class="d-flex">
            <div class="mr-2">
              <b-avatar size="1.5rem" class="my-auto"></b-avatar>
            </div>

            <div class="my-auto" v-if="getUserDetail().user">
              {{ getUserDetail().user.email }}
            </div>
          </div>
        </template>
        <b-dropdown-item @click="handleLogout">Logout</b-dropdown-item>
      </b-dropdown>
    </div>
    <b-modal
      ref="logOutModal"
      id="logOutModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <h4>ออกจากระบบ</h4>
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('logOutModal')"
        >
          ×
        </button>
      </div>
      <div class="text-center px-md-5 py-4">
        <p>
          คุณแน่ใจหรือไม่ว่าคุณต้องการออกจากระบบ?
        </p>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button
          class="mr-2 btn-modal"
          @click="$bvModal.hide('logOutModal')"
          >ไม่</b-button
        >
        <b-button
          class="ml-2 btn-modal-submit"
          @click="logout"
          >ใช่</b-button
        >
      </div>
    </b-modal>

    <b-modal
      ref="alertModal"
      id="alertModal"
      hide-header
      hide-footer
      centered
      size="sm"
      no-close-on-backdrop
    >
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 img"
        />
        <p class="font-weight-bold">กรุณารอสักครู่...</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TheHeaderDropdownAccnt",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUserDetail"]),
  },
  methods: {
    handleLogout() {
      this.$refs.logOutModal.show();
    },
    logout: async function() {
      this.$refs.logOutModal.hide();
      this.$refs.alertModal.show();
      var timestamp = new Date().getTime();
      this.$cookies.set("timestamp", timestamp);
      this.$cookies.remove("tnl-username");
      this.$cookies.remove("token");
      this.$cookies.remove("username");
      this.$cookies.remove("branch_id");
      this.$cookies.remove("plant_id");
      this.$cookies.remove("user_guid");
      this.$cookies.remove("booth");
      this.$cookies.remove("spa");
      this.$cookies.remove("sales_no");
      this.$cookies.remove("saleId");

      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
#avatarUser {
  background-color: #b07ec9;
  color: #8f20c6;
}
::v-deep .lang-dd button {
  background-color: transparent;
  border: none;
  padding: 0;
}

::v-deep .lang-dd button:focus,
::v-deep .lang-dd button:active,
::v-deep .lang-dd.show > .btn-secondary.dropdown-toggle,
::v-deep .lang-dd button:hover {
  background-color: transparent !important;
  border: none;
  padding: 0;
}

.lang-dd {
  border: 1px solid #dcdcdc;
  padding: 5px 10px;
}

::v-deep .lang-dd ul {
  width: 100px;
}

.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}


.btn-modal-submit {
  width: 100px;
  font-weight: bold;
  /* background-color: #8f20c6 !important; */
  /* border-color: #8f20c6; */
}

.img {
  width: 100px;
}
</style>

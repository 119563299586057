<template>
  <div>
    <b-table
      responsive
      striped
      hover
      show-empty
      empty-text="No matching records found"
      class="table-main table-custom"
      :no-border-collapse="false"
      :items="items"
      :fields="fields"

    >
      <template v-slot:cell(action_time)="{ item }">
        {{ $moment(item.action_time).format("DD/MM/YYYY (HH:mm:ss)") }}
      </template>
 
      <template v-slot:cell(status)="{ item }">
        <div :class="item.status === 'reject' ? 'text-danger' : 'text-success'">  {{ item.status == 'reject' ? 'Reject': 'Approve' }}</div>
     
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>

  </div>
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: "action_time", label: "วันที่" },
        { key: "action_by", label: "ผู้ดำเนินการ" },
        { key: "rqp_no", label: "รายการ" },
        { key: "status", label: "Action" },
      ],
      filter: {
        page: 1,
        take: 10,
      },
      isBusy: false,
      items: [],
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      items:[],
    };
  },
  created(){
    this.getList()
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let payload = { rqp_id: this.$route.params.id, serach: "" };

      await this.axios
        .post(`${this.$baseUrl}/rqp/logs`, payload)
        .then((data) => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail.logs;
  
          }
        });

      this.isBusy = false;
    },

  },
};
</script>

<style></style>
